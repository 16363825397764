<template>
  <div>
    <v-dialog max-width="400" v-model="EntitiestoRAdialog" persistent>
        <v-card v-if="RAUserObj || SystemisRA && userIsAdmin">
            <v-card-title>
                {{RAUserObj ? RAUserObj.Full_Name : userLoggedIn.Full_Name}} - you are adding Entities to RA templates
            </v-card-title>
            <v-card-text>
                 <v-window v-model="ratemplatestep">
                <v-window-item :value="1">
                    <v-list-item v-if="OmittedDefaultSystemEntities.length > 0">
                    <v-btn @click="Advanceratemplatestep(SelectedEntities,true)" dark outlined color="error"> Install {{OmittedDefaultSystemEntities.length}} Missing Entities</v-btn>
                    </v-list-item>
                    <v-switch v-model="AllSystemEntities" label="AllSystemEntities"
                    />
                    <!-- Technicall all system entities should be all entities pertaining to ActiveSuiteApp, not just ALL entities... -->
                <v-select v-model="SelectedEntities" :items="EntityTemplateSelectionOptions" return-object
                item-text="DisplayName" multiple
                label="Entity Selection" />
                </v-window-item>
                <v-window-item :value="2">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="table in SelectedEntities" :key="table.itemObjKey">
                            <v-expansion-panel-header>
                                {{table.DisplayName}}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-list>
                                     <v-list-item dense>
                                          <v-select
                                        :label="'Entity Type'" item-text="Name"
                                        :items="EntityTypeOptions" return-object
                                        v-model="table.Entity_Type"
                                    ></v-select>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont">
                                        <v-text-field :rules="CheckReservedProps('DisplayName')"
                                        :label="'Table Name (Multiple '+table.DisplayName+')'"
                                        v-model="table.DisplayName"
                                    ></v-text-field>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont">
                                        <v-text-field :rules="CheckReservedProps('SingleName')"
                                        :label="'Single (1 '+table.SingleName+')'"
                                        v-model="table.SingleName"
                                    ></v-text-field>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont">
                                        <v-text-field
                                        label="Icon"
                                        v-model="table.Table_Icon"
                                    ></v-text-field>
                                    <v-icon>{{table.Table_Icon}}</v-icon>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont">
                                        <v-text-field
                                        label="Primary Field Name" :readonly="table.Entity_Type && table.Entity_Type.Name === 'Store Order'"
                                        v-model="table.Primary_Field_Name"
                                    ></v-text-field>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont">
                                        <v-select
                                        :label="table.Security_Structure && table.Security_Structure.Name === 'Warehouse' ? 'Security Structure (Cannot Change)' : 'Security Structure'" item-text="Name"
                                        :items="EntitySecurityOptions" return-object :readonly="table.Security_Structure && table.Security_Structure.Name === 'Warehouse'"
                                        v-model="table.Security_Structure"
                                    ></v-select>
                                    </v-list-item>
                                    <v-list-item dense class="settingsfont justify-center">
                                        <ContentEditableField style="padding:15px;"
                                        :FieldObject="table" :FieldName="'Description'"
                                        :FieldValue="table.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                                    </v-list-item>
                                    <v-list-item dense>
                                        <v-switch @change="CheckUpdatesTemplate(table)" v-model="table.UpdatesTemplate" label="Update Existing Template"
                                        />
                                    </v-list-item>                                    
                                </v-list>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                <!-- <v-select v-model="SelectedEntities" :items="EntityTemplateSelectionOptions"
                label="Entity Selection" multiple/> -->
                </v-window-item>
                 <v-window-item :value="3">
                    <div v-if="TableTemplateUpdate">
                        <!-- <v-select v-model="TableTemplateUpdate.Templateid" item-text=""
                        :items="EntitiesArray" label="Template to Update"/> -->
                        <v-autocomplete placeholder="Template to Update"
                     :filter="enttemplatefieldFilter" :items="EntitiesArray"
                    :item-text="'id'" v-model="TableTemplateUpdate.Templateid">
                    <template v-slot:selection="data">
                            <v-list-item-content>
                                <v-list-item-subtitle v-html="data.item.DisplayName"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                           <template v-slot:item="data">
                            <v-list-item-content>
                                <v-list-item-title  v-html="data.item.DisplayName"></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.id"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                    </v-autocomplete>
                    </div>
                 </v-window-item>
                 <v-window-item :value="4">
                    <v-list>
                        Do you want to install these now?
                        <v-list-item v-for="ent in OmittedDefaultSystemEntities" :key="ent.itemObjKey">
                            {{ent.DisplayName}}
                        </v-list-item>
                    </v-list>
                 </v-window-item>
                 </v-window>
                 <!-- <v-select v-model="SelectedEntities" :items="EntityTemplateSelectionOptions"
                label="Entity Selection" multiple/> -->
            </v-card-text>
            <v-card-actions>
                <v-btn dark color="warning"
                @click="CancelEntitiestoRAdialog()"
                >
                Cancel
                </v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn :disabled="ratemplatestep !== 3" @click="Regressratemplatestep()" dark color="blue">
                    Back
                </v-btn>
                <v-btn :disabled="SelectedEntities.length === 0 && !InstallingOmittedEntities" @click="Advanceratemplatestep(SelectedEntities,InstallingOmittedEntities)" dark color="success">
                    Next
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
       <v-layout  column  v-if="SmallView"
            style="z-index:7;position: fixed;bottom: 60px;left: 30px;z-index:50;" >
            <v-btn icon x-large @click="ToggleSiteSecondaryDialog()"><v-icon  color="purple" x-large>mdi-bullhorn</v-icon></v-btn>
            <v-btn icon x-large @click="ToggleSiteDialog()"><v-icon  color="links" x-large>mdi-menu</v-icon></v-btn>							
        </v-layout>
        <!-- v-if="!$route.meta.DataSingleBuilder || $route.name === 'SystemConfig'" -->
      <v-app-bar v-if="!MobileView" width="100%" style="top:0px;position: fixed;z-index:25;font-variant-caps: all-petite-caps;">
       <v-list width="100%" dense class="transparent">
        
         <v-list-item dense>
           <v-menu nudge-right="30" 								
                :close-on-content-click="false"                            									
                transition="scale-transition"                            									
                >									
                <template v-slot:activator="{ on }">	
                <v-icon  v-if="FavoritesActive"
                v-on="on" 
                >
                  mdi-star
                </v-icon>
                </template>
                <v-card flat width="200">
                    <v-list class="settingsfont" dense >
                        <v-list-item @click="AddPageAsFavorite()">
                            <v-list-item-title v-if="!CurrentisFavorite" class="navoverline"><v-icon style="padding-right: 5px;" size="18">mdi-star-outline</v-icon>Add as Favorite</v-list-item-title>
                            <v-list-item-title v-if="CurrentisFavorite" class="navoverline"><v-icon style="padding-right: 5px;" size="18">mdi-star-off</v-icon>Remove Favorite</v-list-item-title>
                        </v-list-item>
                        <v-menu nudge-right="90"                           									
                          transition="scale-transition"                            									
                          >									
                          <template v-slot:activator="{ on }">	
                            <v-list-item v-on="on">
                              <v-list-item-title class="navoverline"><v-icon style="padding-right: 5px;" size="18">mdi-star</v-icon>My Favorites</v-list-item-title>
                          </v-list-item>
                          </template>
                          <v-list dense >
                          <v-virtual-scroll
                              :items="NavUserFavorites"
                              :height="NavUserFavorites.length > 4 ? 200 : NavUserFavorites.length*45"
                              item-height="40"                  
                            >
                            <template v-slot:default="{ item }">
                              
                              <v-list-item  class="settingsfont"
                                    :to="item.Path"
                                > 
                                    <v-list-item-title class="navoverline">{{ item.title }}</v-list-item-title>
                                </v-list-item>
                             
                            </template>
                          </v-virtual-scroll>
                           </v-list>
                        </v-menu>
                    </v-list>
                </v-card>
            </v-menu>
           <v-icon class="actionicon mx-2" @click="ToggleAppDarkMode()">
                      mdi-invert-colors
                    </v-icon>
           <v-list-item-content >
            App: <v-overflow-btn style="padding-top:15px;widht:50px;" @change="CheckSuiteAppRoute()"
              :items="UserApps" placeholder="App" v-model="SelectedApp"
                item-text="Name"
                  class="elevation-0 noborderoverflow"
                  dense return-object
                  >
                  <template v-slot:no-data>
                    <v-list-item>
                    You do not have any modules purchased. In the screen below you should really choose a module. 
                    </v-list-item>
                </template>
                  </v-overflow-btn>
           </v-list-item-content>
           <v-list-item-content v-if="SiteSearchActive || AppNavbarSecondary">
               <v-text-field v-if="SiteSearchAppBarSecondary || !AppNavbarSecondary && SiteSearchActive"
               style="padding-top:20px;" label="Search" append-icon="mdi-file-find-outline"  v-model="sitesearch" @keydown.enter="ActivateSearch()"/>
               <v-select return-object v-if="WarehouseSelectorAppBarSecondary" item-text="Name" :items="$store.state.WarehousesArray"
               style="padding-top:20px;" label="Warehouse" append-icon="mdi-warehouse" v-model="SelectedWarehouse" @change="SetActiveWarehouse(SelectedWarehouse)"/>
           </v-list-item-content>
            <v-list-item-actions>
              <v-btn dark color="success white--text">
                  Save
                  <v-icon >mdi-content-save</v-icon></v-btn>
            </v-list-item-actions>
         </v-list-item>
        
       </v-list>
      </v-app-bar>
      <!-- !$route.meta.DataSingleBuilder? 'z-index:8;height: 100%;top:10px;position: fixed;padding-bottom:100px;'
        : 'z-index:2;height: 100%;top:60px;position: fixed;' -->
       <v-navigation-drawer mini-variant-width="100"
        app hide-overlay  :mini-variant.sync="primarymini"
        v-model="SiteDialog" v-if="SiteDialog"
        :permanent="!MiniView"
        :style="'z-index:8;height: 100%;top:10px;position: fixed;padding-bottom:100px;'"
        >	
        <div v-if="IsolatedPrimaryNavbarItem" style="margin-top: 50px;">
            
            <!-- <v-layout class="justify-center">
            <v-btn large dark outlined color="purple" @click="ToggleIsolatedNavbarItem()">
                Back
            </v-btn>
            </v-layout> -->
            <v-list-item style="align-items: center;" dense class="overline SoftBuilderGradient">
                 <v-list-item-action>
                     <v-btn dark outlined color="purple" @click="ToggleIsolatedNavbarItem()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>  
             </v-list-item-action>    
             {{IsolatedPrimaryNavbarItem.title}}
             <v-list-item-action>
             <v-icon>{{IsolatedPrimaryNavbarItem.icon}}</v-icon>     
             </v-list-item-action>        
            </v-list-item>
            <v-divider>
            </v-divider>
            <v-list v-if="IsolatedPrimaryNavbarItem.Children && !primarymini">
            <v-list-item dense 
            v-for="main in IsolatedPrimaryNavbarItem.Children"
            :key="main.itemObjKey" style="padding-left:30px;"
            @click="ActivateSelectedMain(main)"
            >
             <v-list-item-icon>
                    <v-icon>{{main.icon}}</v-icon><v-badge color="error" v-if="main.notifications" overlap >
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{main.title}}
                    </v-list-item-title>
            </v-list-item>
            </v-list>
        </div>
        <div v-if="!IsolatedPrimaryNavbarItem" style="margin-top: 60px;">
            <!-- ,opacity: 0.4 -->
        <v-layout
        style="padding: 10px;" class="justify-center" v-if="userIsAdmin && !primarymini && !UserView">
        <v-btn :to="'/Getting-Started'" small outlined color="purple">Back to Suite</v-btn>
        <v-btn v-if="RAAdmin || SystemisRA && userIsAdmin" @click="ActivatePackagetoRADialog()" small outlined color="success"><v-icon>mdi-cash-register</v-icon>Market</v-btn>
        </v-layout>
        <!-- <v-layout
        style="padding: 10px;" class="justify-center" v-if="RAAdmin && !SystemisRA">
        <v-btn  @click="ActivatePackagetoRADialog()">Package RA (Entities)</v-btn>
        </v-layout> -->
        <div v-if="userIsAdmin && !primarymini && !UserView" class="SoftBuilderGradient">
        <v-layout class="SoftBuilderGradient" :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')',Padding: '10px;' }"
         align-center>
         <!-- <div class="cloudbg" :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')',padding: '10px;' }"/> -->
            <v-flex xl6>
            <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
            <v-progress-circular v-on="on" v-bind="attrs"
        :rotate="360"
        :size="80"
        :width="15"
        :value="80"
        color="purple"
        >
        4/5
        </v-progress-circular>
            </template>
            <span v-html="ActiveViewItem ? ActiveViewItem.Tootlip : 'Select an item'">
            </span>
            </v-tooltip>
            </v-flex>
            <v-flex xl6 >
            Let's set up your {{ActiveSuiteApp.Name}} App
            </v-flex>               
        </v-layout> 
        </div>
        <v-divider></v-divider>
        <v-list-item dense v-if="userIsAdmin && !primarymini">
            <v-switch class="settingsfont" dense
                v-model="UserView" label="Preview" 
            /><v-switch class="settingsfont" dense
                v-model="EditingMenu" label="Edit"
            />
        </v-list-item>
        <v-list-item dense v-if="userIsAdmin && EditingMenu && !primarymini">
            <v-select class="settingsfont" @change="UpdateAppProp('MenuFilters')" dense multiple :items="ComputedDefaultMenuItems" item-text="title"
                v-model="ActiveSuiteApp.MenuFilters" label="Excluded from Menu"
            />
        </v-list-item>
        <div v-if="userIsAdmin && !UserView">
        <v-list dense v-for="top in ComputedAdminMenuItems" :key="top.itemObjKey" >
           <v-list-item dense class="settingsfont"  @click="top.Children ? MiniViewIsolate(top,'primary') : ActivateSelectedMain(top)">
              <v-list-item-icon>
                    <v-icon>{{top.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{top.title}}</v-list-item-title>               
            </v-list-item>
             <v-list v-if="top.Children && !primarymini">
            <v-list-item dense 
            v-for="main in top.Children"
            :key="main.itemObjKey" style="padding-left:30px;"
            @click="ActivateSelectedMain(main)"
            >
             <v-list-item-icon>
                    <v-icon>{{main.icon}}</v-icon><v-badge color="error" v-if="main.notifications" overlap >
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{main.title}}
                    </v-list-item-title>
            </v-list-item>
            </v-list>
        </v-list>
        </div>
        
        
        <v-list dense v-for="top in ComputedDefaultMenuItemsFiltered" :key="top.itemObjKey">
            <v-list-item dense class="settingsfont"  @click="top.Children && !top.PersistsasView ? MiniViewIsolate(top,'primary') : ActivateSelectedMain(top),MiniViewIsolate(top,'primary')">
                <v-list-item-icon>
                    <v-icon>{{top.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{top.title}}</v-list-item-title>
            </v-list-item>
            <v-list v-if="top.Children && !primarymini">
            <v-list-item dense 
            v-for="main in top.Children"
            :key="main.itemObjKey" style="padding-left:30px;"
            @click="ActivateSelectedMain(main)"
            >
            <v-list-item-icon>
                    <v-icon>{{main.icon}}</v-icon><v-badge color="error" v-if="main.notifications" overlap >
                        <span slot="badge"> {{main.notifications}}</span>
                        </v-badge>
                    </v-list-item-icon>
                    <v-list-item-title>{{main.title}}
                        
                    </v-list-item-title>
            </v-list-item>
            </v-list>
            <v-divider v-if="top.Children"></v-divider>
        </v-list>
        </div>
        </v-navigation-drawer>
        <v-navigation-drawer mini-variant-width="100"
        app hide-overlay :mini-variant.sync="secondarymini"
        v-model="SiteSecondaryDialog"  right v-if="SiteSecondaryDialog"
        :permanent="!MiniView" style="margin-top: 60px;"
    >	
        <v-list-item class="px-2 primary" style="text-align: -webkit-right">
             <v-btn
          icon
          @click.stop="secondarymini = !secondarymini"
        >
          <v-icon>{{secondarymini ? 'mdi-chevron-left' : 'mdi-chevron-right'}}</v-icon>
        </v-btn>        
            <v-list-item-content>
            <v-list-item-title class="settingsfont">{{userLoggedIn.Full_Name}}</v-list-item-title>
            <v-list-item-subtitle class="settingsfont">
            <router-link  :to="UserisGuest ? '/MyAccount' : '/MyProfile'">View My Profile</router-link>
            </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar>
            <img v-if="userLoggedIn.Profile_Photo "
            :src="userLoggedIn.Profile_Photo "    
            style="object-fit: cover;"          
            >
            <img v-if="!userLoggedIn.Profile_Photo "
            src="@/assets/BlankProfilePic.png"   
            style="object-fit: cover;"           
            >                      
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item dense class="justify-center" v-if="!secondarymini">
          <v-btn small outlined v-if="CanToggleSocialModule" @click="IntranetViewToggle" >
          <v-icon dark>
              mdi-repeat
            </v-icon>Switch to {{System.Social_Module_Name}}
        </v-btn>
      </v-list-item>
        <v-list dense v-for="top in ComputedSecondaryMenuItems" :key="top.itemObjKey">
            <v-list-item dense class="settingsfont"  @click="top.Children ? MiniViewIsolate(top,'secondary') : ActivateSelectedMain(top)">
                <v-list-item-icon>
                    <v-icon>{{top.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{top.title}}</v-list-item-title>
            </v-list-item>
            <v-list v-if="top.Children && !secondarymini">
            <v-list-item dense  
            v-for="main in top.Children"
            :key="main.itemObjKey" style="padding-left:30px;"
            @click="ActivateSelectedMain(main)"
            >
            <v-list-item-title>
                <v-icon small style="padding-right: 5px;">{{main.icon}}</v-icon>
                {{main.title}}
                    <v-badge color="warning" v-if="main.notifications" left>
                <span slot="badge"> {{main.notifications}}</span>
                </v-badge>
            </v-list-item-title>
            </v-list-item>
            </v-list>
            <v-divider v-if="top.Children"></v-divider>
        </v-list>
        </v-navigation-drawer>
        <v-bottom-navigation v-if="MobileView" class="SoftBuilderGradient" style="position: fixed;bottom: 50px;z-index:10">
            <v-menu v-model="main.Menu" top :offset-y="50"
             v-for="main in PrepareActiveSuiteFullMenuItems"  :key="main.itemObjKey"
            >
            <template v-slot:activator="{ on, attrs }">                
                <v-btn
                v-bind="attrs"
                v-on="on"
                >
                 <span>{{main.title}}</span>
                <v-icon small>{{main.icon}}</v-icon>
                <v-badge color="warning" v-if="main.notifications" left>
                    <span slot="badge"> {{main.notifications}}</span>
                    </v-badge>
                    </v-btn>
                </template>
                <v-list dense min-width="100" class="justify-start" tile>
                    <v-list-item dense @click="SelectBottomNavMenuItem(subitem)" v-for="subitem in main.Children" :key="subitem.itemObjKey">
                        <v-menu  v-model="subitem.Menu" :offset-y="50" top nudge-right="30">
                            <template v-slot:activator="{ on, attrs }">
                            <v-list-item  v-bind="attrs" :href="subitem.RouteBlank? subitem.Path : ''"
                                v-on="on" @click="subitem.Children ? '' : SelectBottomNavMenuItem(subitem)">
                                <v-list-item-title class="caption justify-space-between">
                                
                                <v-icon :color="ThemeColor">{{ subitem.icon }}</v-icon><v-badge color="warning" v-if="subitem.notifications" left>
                                        <span slot="badge"> {{subitem.notifications}}</span>
                                        </v-badge>
                                {{subitem.title}} <v-icon style="right:0px;" v-if="subitem.Children">mdi-chevron-right</v-icon>
                                </v-list-item-title>         
                            </v-list-item>  
                            </template>
                            <v-list min-width="200" class="justify-start" tile v-if="subitem.Children">
                            <v-virtual-scroll
                                    :items="subitem.Children"
                                    :height="subitem.Children.length > 4 ? 200 : subitem.Children.length*45"
                                    item-height="40"                  
                                >
                                <template v-slot:default="{ item }">
                                    <v-list-item @click="SelectBottomNavMenuItem(item)"  :href="item.RouteBlank? item.Path : ''" class="caption" v-if="!item.Children">
                                        <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>{{item.title}}
                                    </v-list-item>
                                </template>
                                </v-virtual-scroll>
                                    </v-list>
                            </v-menu> 
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-bottom-navigation>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'
export default {
    props: ['System','SystemEntities','SitePages','SocialEntities','SystemPhotoLibraries','GroupEntities','GraphicalElements','GradientElements','DocumentationLibraries',
    'RADB','RAApp','UserDBNavList','UnreadNotifications','ActiveSearch'],
    components: {ContentEditableField},
    data() {
        return {
            TableTemplateUpdate: '',
            EntitiestoRAdialog: false,
            AllSystemEntities: false,
            SelectedEntities: [],
            ratemplatestep: 1,
            InstallingOmittedEntities: false,
            IsolatedPrimaryNavbarItem: '',
            IsolatedSecondaryNavbarItem: '',
            EditingMenu: false,
            UserView: false,
            UserDashboards: [],
            sitesearch: '',
            SelectedWarehouse: '',
            secondarymini: true,
            primarymini: false,
            SiteSecondaryDialog: false,
            SelectedApp: '',
            SelectedMain: '',
            SelectedMainDialog: false,
            AdminMenuItems: [
                
                {title: 'Plugins',icon: 'mdi-apps'},
                {title: 'Settings',icon: 'mdi-cogs',Children: [
                    {title: 'Administration',icon: 'mdi-file-document',ComponentName: 'General',ComponentPath: 'views/DataImports',Required: ['Data_Importer']},
                    {title: 'General',icon: 'mdi-pencil',ComponentName: 'General',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/General'},
                    {title: 'Security',icon: 'mdi-security-network',ComponentName: 'Security',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Security',Required: []},
                    {title: 'Team',icon: 'mdi-account-group',ComponentName: 'Team',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Team',Required: ['Timesheets','Activities_and_Team','Site_Articles','Site_Blogs','Site_Forums','Site_Meetups','Site_Polls']},
                    {title: 'Directories',icon: 'mdi-view-list',ComponentName: 'General',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Database',Required: ['Custom_Directory','Digital_Business_Cards','Online_Store']},
                    //cannot configure ,'Group_Categories','Group_Featured_Members','Classifieds' here, done on Suite Level
                    {title: 'Calendars',icon: 'mdi-calendar',ComponentName: 'General',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Database',Required: ['Booking_Schedule','Calendar_Jobs']},
                    {title: 'Store',icon: 'mdi-cart',ComponentName: 'General',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Database',Required: ['Online_Store']},
                    {title: 'Warehouses',icon: 'mdi-warehouse',ComponentName: 'Warehouses',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Warehouses',Required: ['Warehouse_&_Products']},
                    {title: 'Database',icon: 'mdi-database',ComponentName: 'Database',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Database',
                    Required: ['Standard_Data_Table','Calendar_Jobs','Custom_Directory','Billing_Accounts','Events_Management','Ticketing Table','Cash_Loans',
                    'Inventory_Article','Purchase_Invoices','Purchase_Orders','Sales_Invoices','Sales_Quotes','Store_Products','Store_Suppliers']},
                ]},
                
            ],
            DefaultMenuItems: [
                // {title: 'Accounting',icon: 'mdi-bank'},
                //{title: 'Administration',icon: 'mdi-file-document',Accepted: ['Data_Importer']},
                {title: 'Dashboards',icon: 'mdi-monitor-dashboard',PersistsasView: true,ComponentName: 'Dashboard Builder',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Dashboard',
                Children: [
                    {title: 'Accounting',icon: 'mdi-bank',Accepted: ['Online_Store','Monetization'],CustomChildren: ['AccountingLedger']},//Needs comoponent calling the ledger books...clickign changes route to book 
                    {title: 'BI & Charts',icon: 'mdi-chart-bar',Accepted: ['Custom_Dashboard','Geolocation']},
                    
                    {title: 'Team Dashboard',icon: 'mdi-account-group',Accepted: ['Activities_and_Team','Goals','Timesheets']},
                ]
                ,Accepted: ['Activities_and_Team','Goals','Timesheets'],CustomChildren: ['ActiveUserDashboards','ActivePipelines']
                },  
                {title: 'Forms',icon: 'mdi-form-select',Accepted: [],CustomChildren: ['NavigatedWebForms']},
                {title: 'Calendar',icon: 'mdi-calendar',Accepted: ['Calendar_Jobs','Booking_Schedule']},        
                {title: 'Warehouse Products',icon: 'mdi-warehouse',Accepted: ['Warehouse_&_Products']},
                //{title: 'Accounts',icon: 'mdi-cash-register',Accepted: ['Online_Store']},
                {title: 'Sales',icon: 'mdi-cash-register',Accepted: ['Warehouse_Outbound','Online_Store']
                ,CustomChildren: ['AccountingLedger','BillingAccounts']
                },
                {title: 'Accounts',icon: 'mdi-bank',Accepted: ['Warehouse_Outbound','Online_Store','Cash_Loans']
                ,CustomChildren: ['UserCompanies']
                },
                {title: 'Purchases',icon: 'mdi-cash-register',Accepted: ['Warehouse_Inbound'],CustomChildren: ['WarehouseInboundOptions']},
                {title: 'Warehouse Outbound',icon: 'mdi-hand-okay',Accepted: [],CustomChildren: ['WarehouseOutboundOptions']},
                {title: 'Database',icon: 'mdi-database',Accepted: ['Standard_Data_Table','System_Tickets']},
                {title: 'Suites',icon: 'mdi-apps',Accepted: [],CustomChildren: ['ActiveSuites','BillingAccounts']},
                {title: 'Suite Settings',icon: 'mdi-cogs',Accepted: [],CustomChildren: ['ActiveSuiteSettings']},
            ],
            SecondaryMenuItems: [
                //FavoritesActive
                // {title: 'Favorites',icon: 'mdi-start',Accepted: ['Favorites']}, 
                // {title: 'Dashboards',icon: 'mdi-monitor-dashboard',PersistsasView: true,ComponentName: 'Dashboard Builder',ComponentPath: 'components/SuiteBuilder/SuiteApps/Settings/Dashboard',
                // Children: [
                //     {title: 'Accounting',icon: 'mdi-bank',Accepted: ['Accounting_Ledger']},//Needs comoponent calling the ledger books...clickign changes route to book 
                //     {title: 'BI & Charts',icon: 'mdi-chart-bar',Accepted: ['Custom_Dashboard','Geolocation']},
                    
                //     {title: 'Team Dashboard',icon: 'mdi-account-group',Accepted: ['Activities_and_Team','Goals','Timesheets']},
                // ]
                // ,Accepted: ['Activities_and_Team','Goals','Timesheets'],CustomChildren: ['ActiveUserDashboards','ActivePipelines']
                // },
                {title: 'Social Calendar',icon: 'mdi-calendar',Accepted: ['Site_Meetups','Site_Events','Booking_Schedule']},
                {title: 'Campaigns',icon: 'mdi-monitor-cellphone-star',Accepted: ['Campaign_Builder'],CustomChildren: ['CampaignBuilder']},
                {title: 'Social',icon: 'mdi-account-circle',Accepted: ['Social_Centre']},
                {title: 'Communication',icon: 'mdi-bullhorn',Accepted: ['Site_Articles','Site_Blogs','Digital_NoticeBoard','Site_Polls']},  
                {title: 'Knowledge Base',icon: 'mdi-wikipedia',Accepted: ['Site_Forums','Document_Library','Glossary','Photo_Library','FAQ','Documentation_Library','Digital_Business_Cards']},
                {title: 'Directories & Personals',icon: 'mdi-newspaper',Accepted: ['Group_Directories','Custom_Directory','Classifieds']},
                
            ],
            WarehouseActions: [
              {Pluginid: 'Warehouse_Inbound',Display: 'Purchase Requisitions',Icon: 'mdi-file-edit',Prop: 'Requisitions', ArrayName: 'PurchaseRequisitions',Description: 'View the items on Backorder, that need to be converted to Purchase Orders with Suppliers.',Path: 'BackorderProcessing/'},
              {Pluginid: 'Warehouse_Inbound',Display: 'Purchase Orders',Icon: 'mdi-file-check',Prop: 'Ordering', ArrayName: 'PurchaseOrders',Description: 'View Purchase Orders, placed with Suppliers.',Path: 'TBD/'},
              {Pluginid: 'Warehouse_Inbound',Display: 'Receiving',Icon: 'mdi-warehouse',Prop: 'Receiving', ArrayName: 'GoodsReceivingVouchers',Description: 'Receive delivered goods from open Purchase Orders.',Path: 'GoodsReceiving/'},
              {Pluginid: 'Warehouse_Outbound',Display: 'Picking',Icon: 'mdi-hand-okay',Prop: 'Picking', ArrayName: 'PickingSlips',Description: 'View Picking Slips in Progress.',Path: 'Picking/'},
              {Pluginid: 'Warehouse_Shipping',Display: 'Packing',Icon: 'mdi-gift',Prop: 'Packing', ArrayName: 'PackingLists',Description: 'View Packing Lists in Progress.',Path: 'TBD/'},
              {Pluginid: 'Warehouse_Outbound',Display: 'Dispatching',Icon: 'mdi-cube-send',Prop: 'Dispatching', ArrayName: 'DispatchInstructions',Description: 'View items ready for Dispatching.',Path: 'Dispatching/'},
            ],
            EntityTemplateFields: ['DisplayName','SingleName','Description','Entity_Type','Table_Icon','Security_Structure','Primary_Field_Name']
        }
    },	
    computed:{
        OmittedDefaultSystemEntities(){
            return this.DefaultSystemEntities.filter(ent => {
                return !this.SystemEntities.find(obj => obj.DisplayName === ent.DisplayName)
            })
        },
        DefaultSystemEntities(){
        return this.EntitiesArray
        .filter(ent => {
          //Entities on RA db has boolean "Default_System_Entity" and this maarks the RA store Template as a default install entity 
          return ent.Default_System_Entity
        })
        },
        EntitiesArray(){
            return this.$store.state.EntitiesArray
        },
        EntitySecurityOptions(){
            let field = this.EntityMasterFields.find(obj => obj.id === 'Security_Structure')
            return field ? field.Options : field
        },
        EntityTypeOptions(){
            let field = this.EntityMasterFields.find(obj => obj.id === 'Entity_Type')
            return field ? field.Options : field
        },
        ReservedDisplayNameProps(){
            return this.$store.state.ReservedDisplayNameProps
        },
        ReservedSingleNameProps(){
            return this.$store.state.ReservedSingleNameProps
        },
        ComputedEntityTemplateFields(){
            return this.EntityTemplateFields.map(fieldname => {
                return this.EntityMasterFields.find(obj => obj.id === fieldname)
            })
        },
        EntityMasterFields(){
            return this.$store.state.EntityMasterFields
        },
        EntityTemplateSelectionOptions(){
            let entities = this.AppEntities
            if(this.AllSystemEntities){
                //again now think about it..this should only install entities rlevant to the ActiveSuiteApp, not all system entities...
                entities = this.SystemEntities
            }
            return entities
            .map(ent => {
                let entobj = {id: ent.id}
                this.EntityTemplateFields.map(field => {
                    if(typeof ent[field] !== 'undefined'){
                        entobj[field] = ent[field]
                    }
                })
                return entobj
            })
        },
        SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        UserModerationPermissions(){
            return this.userLoggedIn.ModerationPermissions
        },
        CampaignModerator(){
            return this.UserModerationPermissions && this.UserModerationPermissions.includes('Landing Pages') || 
            this.UserModerationPermissions && this.UserModerationPermissions.includes('Banner Ads') || this.userIsAdmin
        },
        CampaignBuilder(){
            let menuent = {
                icon: 'mdi-monitor-cellphone-star',
                title: 'Campaign Builder',
                Route: 'Campaigns'
            }
            return [
                menuent
            ].filter(item => {
                return this.CampaignModerator
            })
        },
        AccountingLedger(){
            let menuent = {
                icon: 'mdi-bank',
                title: 'Ledger',
                Route: 'Ledgers/General'
            }
            return [
                menuent
            ].filter(item => {
                //console.log(item)
                return this.AppandSharedPlugins.find(obj => obj.id === 'Online_Store')
                || this.AppandSharedPlugins.find(obj => obj.id === 'Monetization')
                || this.AppandSharedPlugins.find(obj => obj.id === 'Cash_Loans')
                //return item
            }).filter(item => {
                return this.userIsAdmin || this.userIsEagleViewer
            })
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        UserCompanies(){
            let ent = this.SystemEntities.find(obj => obj.id === 'User_Companies')
            if(ent){
                let menuent = {
                icon: ent.Table_Icon,
                title: ent.DisplayName,
                Route: ent.DisplayName.split(' ').join('')
            }
            return [
                menuent
            ].filter(item => {
                return this.AppandSharedRAPlugins.find(obj => obj.id === 'User_Companies')
            })
            }
            else{
                return []
            }
        },
        BillingAccounts(){
            let ent = this.SystemEntities.find(obj => obj.id === 'Billing_Accounts')
            if(ent){
                let menuent = {
                icon: ent.Table_Icon,
                title: ent.DisplayName,
                Route: ent.DisplayName.split(' ').join('')
            }
            return [
                menuent
            ].filter(item => {
                return this.AppandSharedRAPlugins.find(obj => obj.id === 'Online_Store') ||
                this.SystemisRA && this.AppEntities.find(obj => obj.id === 'Systems') || 
                this.AppandSharedRAPlugins.find(obj => obj.id === 'Cash_Loans')
            })
            }
            else{
                return []
            }
            
        },
        RAAdmin(){
            return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
        ActiveWarehouse(){
            return this.$store.state.ActiveWarehouse
        },
        WarehouseInboundOptions(){
            return this.WarehouseappInbound ? this.WarehouseActions.filter(item => {
                return item.Pluginid === 'Warehouse_Inbound'
            })
            .map(act => {
                ///,Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'
                let menuitem = {
                    title: act.Display,icon: act.Icon, Route: act.Path+this.ActiveWarehouse.id
                }
                return menuitem
             }) : []
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        ActiveSuiteSettings(){
            return this.SystemisRA ? this.SystemEntities.filter(item => {
                // console.log(this.AppEntities)
                return this.AppEntities.find(obj => obj.id === item.id)
            }).filter(ent => {
                        let storequery = ent.DisplayName.split(' ').join('')+'Query'
                        return this.$store.state[storequery]
                    })  
            .map(ent => {
                ///,Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'
                let menuitem = {
                    title: ent.DisplayName,icon: ent.Table_Icon, Route: ent.DisplayName.split(' ').join('')
                }
                return menuitem
             }) : []
        },
        ActiveSuites(){
            return this.SystemisRA ? this.SystemEntities.filter(item => {
                return item.Entity_Type && item.Entity_Type.Name === 'System Builder'
            }).filter(ent => {
                        let storequery = ent.DisplayName.split(' ').join('')+'Query'
                        return this.$store.state[storequery]
                    })  
            .map(ent => {
                ///,Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'
                let menuitem = {
                    title: ent.DisplayName,icon: ent.Table_Icon, Route: ent.DisplayName.split(' ').join('')
                }
                return menuitem
             }) : []
        },
        WarehouseOutboundOptions(){
            return this.WarehouseappOutbound ? this.WarehouseActions.filter(item => {
                return item.Pluginid === 'Warehouse_Outbound'
            })
            .map(act => {
                ///,Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'
                let menuitem = {
                    title: act.Display,icon: act.Icon, Route: act.Path+this.ActiveWarehouse.id
                }
                if(menuitem.title === 'Dispatching'){
                    menuitem.Route = 'Dispatching/'+this.ActiveWarehouseApp.Store_Order_Master+'/'+this.ActiveWarehouse.id
                }
                return menuitem
             }) : []
        },
        WarehouseappInbound(){
        return this.AppandSharedPlugins.find(obj => obj.id === 'Warehouse_Inbound')
        },
        WarehouseappOutbound(){
        return this.AppandSharedPlugins.find(obj => obj.id === 'Warehouse_Outbound')
        },
        ActiveWarehouseApp(){
            return this.ActiveWarehouse ? this.AppsDataBase.find(obj => obj.id === this.ActiveWarehouse.Primary_Appid) : ''
        },
        ActiveWarehouse(){
            return this.$store.state.ActiveWarehouse
        },
        ActivePipelines(){
            let pipelines = this.AppEntitieswithPipelines.map(ent => {
                return ent.EntityProcesses.map(proc => {
                    proc.EntityObj = ent
                    proc.EntityObj.topicid = ent.id
                    return proc
                })
            }).flat()
            return pipelines.map(pipe => {
                let pipelineobj = {
                icon: 'mdi-pipe',
                title: pipe.Name,
                ComponentName: 'Active Pipeline',
                ComponentPath: 'components/SuitePlugins/Pipelines/MyActivePipeline',
                SecondProp: {
                    title: pipe.Name,
                    ComponentName: 'Active Pipeline',
                    ComponentPath: 'components/SuitePlugins/Pipelines/MyActivePipeline',
                    ...pipe.EntityObj
                },
                Method: 'AssignSocialItemInteractMethod',
                Prop: 'setActiveAppComponent'
              }
              return pipelineobj
            })
        },
        AppEntitieswithPipelines(){
            return this.AppEntities.filter(ent => {
                return ent.EntityProcesses && ent.EntityProcesses.length > 0
            }).filter(ent => {
                let pass = false
                let processes = ent.EntityProcesses
                // console.log(processes)
                processes.map(process => {
                    if(process.ProcessStages && process.ProcessStages.length > 0){
                        pass = true
                    }
                })
                return pass
            })
        },
        UsersArray(){
            return this.$store.state.UsersArray
        },
        ActiveUserDashboards(){
         return this.UserDashboards.map(dashboard => {
              let dashboardobj = {
                icon: 'mdi-chart-box',
                title: dashboard.DashboardName,
                IsRoute: true,
                Route: 'CustomDashboard/'+dashboard.id,
              }
              return dashboardobj
            })
            .filter(item => {
                let cbplug = this.AppPluginsOnly.find(obj => obj.id === 'Custom_Dashboard')
                return cbplug && cbplug.Active
            })   
        },
        WarehouseSelectorAppBarSecondary(){
            return this.ActiveSuiteApp.AppNavbarSecondary === 'Warehouse Selector'
        },
        AppNavbarSecondary(){
            return this.ActiveSuiteApp.AppNavbarSecondary
        },
        SiteSearchAppBarSecondary(){
            return this.SiteSearchActive && this.ActiveSuiteApp.AppNavbarSecondary === 'Site Search'
        },
        SiteSearchActive(){
            return this.AppPlugins.find(obj => obj.id === 'Site_Search')
        },
        FavoritesActive(){
            return this.AppPlugins.find(obj => obj.id === 'Favorites')
        },
        CanToggleSocialModule(){
        if(this.UserDBNavList){
            return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page && this.UserDBNavList.Options.SocialModule
        }
        else{
            return this.System && this.SocialNetworkActive && this.System.Social_Landing_Page
        }
        },
        AppPluginsOnlyIDs(){
            return this.AppPluginsOnly.map(plug => {
                return plug.id
            })
        },
        AppPluginsOnly(){
            return this.ActiveSuiteApp.Plugins
        },
        AppandSharedPluginIDs(){
            return this.AppandSharedPlugins.map(plug => {
                return plug.id
            })
        },
        AppandSharedPlugins(){
            return this.ActiveSuiteApp.Plugins.concat(this.ActiveSuiteApp.SharedPlugins)
        },
        AppandSharedRAPlugins(){
            return this.AppPlugins.concat(this.ActiveSuiteApp.SharedPlugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                plugobj.Primary_Appid = plug.Primary_Appid
                return plugobj
            }).filter(plug => {
                return plug.Name
            }) 
            )
        },
        AppPlugins(){
            return this.ActiveSuiteApp.Plugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            })
        },
        RAPlugins(){
            return this.$store.state.RAPlugins
        },
        UserApps(){
            return this.AppsDataBase.filter(item => {
            return this.userLoggedIn.Apps.includes(item.id) || this.userIsAdmin
            })
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        SiteDialog(){
            return this.$store.state.SiteDialog
        },
        UserFavorites(){
      return this.userLoggedIn && this.userLoggedIn.FavoriteLinks ? this.userLoggedIn.FavoriteLinks : []
    },
    NavUserFavorites(){
      return this.UserFavorites.map(fav => {
        let favobj = {
          title: fav.DisplayName,
          icon: '',
          Path: fav.Path,
          IsRoute: true,
          RouteBlank: false
        }
        return favobj
      })
    },
    ComputedSecondaryMenuItems(){
        return this.SecondaryMenuItems.map(topitem => {
            //console.log(topitem)
            if(topitem.Accepted){
                topitem.Children = this.AppandSharedPlugins.filter(plug => {
                    //console.log(topitem.Accepted,plug.id,topitem.Accepted.includes(plug.id))
                    return topitem.Accepted.includes(plug.id)
                }).map(plug => {
                let item = {
                    title: plug.Name,
                    icon: plug.Icon
                }
                if(plug.Installation_Tables){
                    let appplug = this.AppandSharedPlugins.find(obj => obj.id === plug.id)
                    // if(!appplug){
                    //     appplug = this.ActiveSuiteApp.SharedPlugins.find(obj => obj.id === plug.id)
                    // }
                    let tables = []
                    if(appplug && appplug.Installation_Tables){
                        tables = appplug.Installation_Tables
                    }
                    // console.log(tables,plug.id)
                    let itemtables = this.AppEntities.filter(ent => {                        
                        let plugtables = tables.map(table => {
                            return table.id
                        })
                        // console.log(plugtables.includes(ent.id),ent.id)
                        return plugtables.includes(ent.id)
                    })                    
                    .map(ent => {
                        let menuent = {
                            icon: ent.Table_Icon,
                            title: ent.DisplayName,
                            Route: ent.DisplayName.split(' ').join('')
                        }
                        return menuent
                    })
                    item.Children = itemtables
                }
                else{
                    let raplug = this.RAPlugins.find(obj => obj.id === plug.id)
                    item.Method = 'AssignSocialItemInteractMethod'
                    item.Prop = 'setActiveAppComponent'
                    item.SecondProp = {title: item.title}
                    item.Route = 'App/'+this.ActiveSuiteApp.id
                    if(raplug &&raplug.Icon){
                        item.icon = raplug.Icon
                    }
                    if(raplug && raplug.ComponentName){
                        item.ComponentName = raplug.ComponentName
                        item.ComponentPath = raplug.ComponentPath
                    }
                    item.SecondProp = {title: item.title}
                    if(raplug && raplug.ComponentName){
                        item.SecondProp.ComponentName = raplug.ComponentName
                        item.SecondProp.ComponentPath = raplug.ComponentPath
                    }

                }
                //console.log(item)
                return item
            })
            }
            else if(topitem.Children){
                //console.log(topitem)
                topitem.Children.map(item => {
                    item.Method = 'AssignSocialItemInteractMethod'
                    item.Prop = 'setActiveAppComponent'
                    item.SecondProp = {title: item.title}
                    item.Route = 'App/'+this.ActiveSuiteApp.id
                })
                
            }
            return topitem
        })
        .map(topitem => {            
            topitem.Children = topitem.Children.filter(item => {
               return item.SecondProp && item.SecondProp.Children && item.SecondProp.Children.length > 0 || !item.SecondProp || item.SecondProp && !item.SecondProp.Children
            })
            //console.log(topitem.title,topitem.Children)
            return topitem
        })
        .filter(item => {
            return item.Children && item.Children.length > 0 || !item.Children
        })
    },
    ComputedDefaultMenuItemsFiltered(){
        let menufilters = []
        if(this.ActiveSuiteApp.MenuFilters){
            menufilters = this.ActiveSuiteApp.MenuFilters
        }
        return this.ComputedDefaultMenuItems.filter(item => {
            return !menufilters.includes(item.title)
        })
    },
    ComputedDefaultMenuItems(){
        //console.log(this.DefaultMenuItems)
        return this.DefaultMenuItems.map(top => {
            let topitem = Object.assign({},top)
            //console.log(this.AppandSharedPlugins)
            if(topitem.Accepted){
                let basicchildren = this.AppandSharedRAPlugins.filter(plug => {
                    //console.log(plug.id,topitem.Accepted.includes(plug.id),topitem.Accepted)
                    return topitem.Accepted.includes(plug.id)
                }).map(plug => {
                let item = {
                    title: plug.Name,
                    icon: plug.Icon
                }
                if(plug.Installation_Tables){
                    let appplug = this.AppandSharedPlugins.find(obj => obj.id === plug.id)
                    // if(!appplug){
                    //     appplug = this.ActiveSuiteApp.SharedPlugins.find(obj => obj.id === plug.id)
                    // }
                    let tables = []
                    if(appplug && appplug.Installation_Tables){
                        tables = appplug.Installation_Tables
                    }
                    // console.log(tables,plug.id)
                    let itemtables = this.AppEntities.filter(ent => {                        
                        let plugtables = tables.map(table => {
                            return table.id
                        })
                         //console.log(plugtables.includes(ent.id),ent.id)
                        return plugtables.includes(ent.id)
                    }).filter(ent => {
                        let storequery = ent.DisplayName.split(' ').join('')+'Query'
                        return this.$store.state[storequery]
                    })                    
                    .map(ent => {
                        let menuent = {
                            icon: ent.Table_Icon,
                            title: ent.DisplayName,
                            Route: ent.DisplayName.split(' ').join('')
                        }
                        menuent.Notifications = this.UnreadNotifications.filter(not => {
                            return not.topicid === ent.id
                        })
                        if(menuent.Notifications.length > 0){
                            menuent.notifications = menuent.Notifications.length
                        }
                        //console.log('this.UnreadNotifications',this.UnreadNotifications)
                        return menuent
                    })
                    item.Tables = itemtables
                }
                else{
                    item.Method = 'AssignSocialItemInteractMethod'
                    item.Prop = 'setActiveAppComponent'
                    item.SecondProp = {title: item.title}
                    item.Route = 'App/'+this.ActiveSuiteApp.id
                    
                    item.SecondProp = {title: item.title}
                    if(plug.ComponentName){
                        item.ComponentName = plug.ComponentName
                        item.ComponentPath = plug.ComponentPath
                        item.SecondProp.ComponentName = plug.ComponentName
                        item.SecondProp.ComponentPath = plug.ComponentPath
                        if(plug.SummaryComponentPath){
                          item.SecondProp.SummaryComponentPath = plug.SummaryComponentPath  
                          item.SummaryComponentPath = plug.SummaryComponentPath  
                        }                        
                    }
                }
                //console.log(item)
                return item
            })
            if(topitem.CustomChildren && topitem.title !== 'Suites' && topitem.title !== 'Suite Settings' || 
            topitem.title === 'Suites' && topitem.CustomChildren && this.SystemisRA && this.AppEntities.find(obj => obj.id === 'Systems') || 
            topitem.title === 'Suite Settings' && topitem.CustomChildren && this.SystemisRA && this.AppEntities.find(obj => obj.id === 'Entities')){    
                topitem.Children = basicchildren.concat(topitem.CustomChildren.map(child => {
                    //console.log(child,this[child])
                    return this[child]
                }).flat())
            }
            else{
                topitem.Children = basicchildren
            }
            if(topitem.PersistsasView){
                    topitem.Method = 'AssignSocialItemInteractMethod'
                    topitem.Prop = 'setActiveAppComponent'
                    topitem.SecondProp = {title: topitem.title}
                    if(topitem.ComponentPath){
                        topitem.SecondProp.ComponentPath = topitem.ComponentPath
                    }
                    if(topitem.ComponentName){
                        topitem.SecondProp.ComponentName = topitem.ComponentName
                    }
                }
            }
            
            else if(topitem.Children){
                
                topitem.Children.map(item => {
                    item.Method = 'AssignSocialItemInteractMethod'
                    item.Prop = 'setActiveAppComponent'
                    item.SecondProp = {title: item.title}
                    item.Route = 'App/'+this.ActiveSuiteApp.id
                    if(item.Accepted){
                        item.SecondProp.Accepted = item.Accepted
                        item.SecondProp.Children = this.AppandSharedPlugins.filter(plug => {
                            //console.log(plug.id,topitem.Accepted.includes(plug.id),topitem.Accepted)
                            return item.Accepted.includes(plug.id)
                        }).map(plug => {
                            let item = {
                                title: plug.Name,
                                icon: plug.Icon
                            }
                            if(plug.ComponentName){
                                item.ComponentName = plug.ComponentName
                                item.ComponentPath = plug.ComponentPath
                                if(plug.SummaryComponentPath){
                                 item.SummaryComponentPath = plug.SummaryComponentPath   
                                }                                
                            }
                            return item
                        })
                    }
                })
                if(topitem.PersistsasView){
                    topitem.Method = 'AssignSocialItemInteractMethod'
                    topitem.Prop = 'setActiveAppComponent'
                    topitem.SecondProp = {title: topitem.title}
                    if(topitem.ComponentPath){
                        topitem.SecondProp.ComponentPath = topitem.ComponentPath
                    }
                    if(topitem.ComponentName){
                        topitem.SecondProp.ComponentName = topitem.ComponentName
                    }
                }
                
            }
            return topitem
        })
        .map(topitem => {
            let children = []
                topitem.Children.map(item => {
                    if(item.Tables){
                       children = children.concat(item.Tables)
                    }
                    else{
                        children.push(item)
                    }
                })
                topitem.Children = children
                return topitem
        })
        .map(topitem => {            
            topitem.Children = topitem.Children.filter(item => {
               return item.SecondProp && item.SecondProp.Children && item.SecondProp.Children.length > 0 || !item.SecondProp || item.SecondProp && !item.SecondProp.Children
            })
            //console.log(topitem.title,topitem.Children)
            return topitem
        })
        .filter(item => {
            return item.Children && item.Children.length > 0 || !item.Children
        })
    },
        ComputedAdminMenuItems(){
            
            return this.AdminMenuItems            
            .map(itemobj => {
                let item = Object.assign({},itemobj)
                //console.log(this.AppandSharedPluginIDs,item.Children)
                item.Method = 'AssignSocialItemInteractMethod'
                    item.Prop = 'setActiveAppComponent'
                    item.SecondProp = {title: item.title}
                    item.Route = 'App/'+this.ActiveSuiteApp.id
                    if(item.Children){
                        item.Children = item.Children.filter(child => {
                            if(!child.Required){
                                return child
                            }
                            else{
                                return child.Required.some(r=> this.AppPluginsOnlyIDs.includes(r))
                            }
                        }) 
                        item.Children.map(child => {
                            child.Method = 'AssignSocialItemInteractMethod'
                            child.Prop = 'setActiveAppComponent'
                            child.SecondProp = {title: child.title}
                            child.Route = 'App/'+this.ActiveSuiteApp.id
                            if(child.ComponentName){
                                child.SecondProp.ComponentName = child.ComponentName
                            }
                            if(child.ComponentPath){
                                child.SecondProp.ComponentPath = child.ComponentPath
                            }
                        })
                    }
                return item
            })
        },
        IsGroupView(){
            return this.$store.state.IsGroupView
        },
        BuilderView(){
        return this.$store.state.BuilderView
        },
        DocLibBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
        },
        FeaturedMemberBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Featured Member'
        },
        WebsiteBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        DBBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
            return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
        SuiteBuilderView(){
            return !this.DocLibBuilderView && !this.WebsiteBuilderView && !this.DBBuilderView && !this.NetworkBuilderView && !this.FeaturedMemberBuilderView
        },
        BuilderName(){
            return this.SelectedBuilder ? this.SelectedBuilder.Name : ''
        },
        SharedPluginEntities(){
            return this.ActiveSuiteApp.SharedPlugins.map(plug => {
                let tables = []
                if(plug.Installation_Tables){
                    tables = plug.Installation_Tables.map(table => {
                        return table.id
                    })
                }
                return tables
            }).flat()
        },
        NavigatedWebForms(){
            return this.AppWebForms.map(ent => {
                ///,Path: '/Dispatching/'+this.ActiveWarehouse.Sales_Order_Entity+'/'
                let menuitem = {
                    title: ent.DisplayName,icon: ent.Table_Icon, Route: ent.DisplayName.split(' ').join('')
                }
                return menuitem
             })
        },
        AppWebForms(){
            return this.SystemEntities.filter(ent => {
                let SharedWebForms = []
                if(this.ActiveSuiteApp.SharedWebForms){
                    SharedWebForms = this.ActiveSuiteApp.SharedWebForms
                }
                //console.log('SharedWebForms',SharedWebForms)
                return SharedWebForms.includes(ent.id)
            }).filter(ent => {
                        let storequery = ent.DisplayName.split(' ').join('')+'Query'
                        return this.$store.state[storequery]
                    })
            // .map(ent => {
            //     let entobj = Object.assign({},ent)
            //     entobj.Primary_Appid = this.ActiveSuiteApp.id
            //     return entobj
            // })
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                //console.log(ent.id)
                //ent.System_Required changed because on RA it throws in garbage
                return ent.Primary_Appid === this.ActiveSuiteApp.id || ent.System_Required && !this.SystemisRA || this.SharedPluginEntities.includes(ent.id)
            }).filter(ent => {
                        let storequery = ent.DisplayName.split(' ').join('')+'Query'
                        return this.$store.state[storequery]
                    }).concat(this.AppWebForms)
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        AppsPath(){
        return this.$store.state.AppsPath
        },
        RouteObj(){
            return this.$route
        },
        PrepareActiveSuiteFullMenuItems(){
            return this.ActiveSuiteFullMenuItems.map(item => {
                if(typeof item.Menu === 'undefined'){
                    item.Menu = false
                }
                if(item.Children){
                    
                    item.Children = item.Children.map(child => {
                       if(typeof child.Menu === 'undefined'){
                            child.Menu = false
                        }
                        if(child.Children){
                            child.Children = child.Children.map(sub => {
                            if(typeof sub.Menu === 'undefined'){
                                    sub.Menu = false
                                }
                                return sub
                            })
                        child.Notifications = child.Children.filter(sub => {       
                            return sub.Notifications
                        }).map(sub => {
                            return sub.Notifications
                        }).flat()                        
                        if(child.Notifications.length > 0){
                            child.notifications = child.Notifications.length
                        }
                        else{
                            child.notifications = ''
                        }
                        }
                        return child
                    })    
                    item.Notifications = item.Children.filter(child => {       
                        return child.Notifications
                    }).map(child => {
                        return child.Notifications
                    }).flat()
                    if(item.Notifications.length > 0){
                        item.notifications = item.Notifications.length
                    }
                    else{
                        item.notifications = ''
                    }
                }
                return item
            })
        },
        BottomMoreMenuItems(){
            let array = [
                {title: 'My Profile',icon: 'mdi-account',Route: 'MyProfile'},
                {title: 'Toggle',icon: 'mdi-invert-colors',BypassMethod: 'ToggleAppDarkMode'}
            ]
            if(this.userIsAdmin){
                array = [{title: 'Back to Suite',icon: 'mdi-chevron-left',Route: 'Getting-Started'},].concat(array,this.ComputedAdminMenuItems)
            }
            return array
        },
        BottomNavNewsItems(){
            return this.ComputedSecondaryMenuItems.map(item => {
                return item.Children
            }).flat()
        },
        ActiveSuiteFullMenuItems(){
            if(this.userIsAdmin){
                return [
                {title: 'More',icon: 'mdi-dots-horizontal',Children: this.BottomMoreMenuItems},
                {title: 'Menu',icon: 'mdi-menu',Children: this.ComputedDefaultMenuItems},
                {title: 'News',icon: 'mdi-bullhorn',Children: this.BottomNavNewsItems}
                ]
            }
            else {
                return [
                {title: 'More',icon: 'mdi-dots-horizontal',Children: this.BottomMoreMenuItems},
                {title: 'Menu',icon: 'mdi-menu',Children: this.ComputedDefaultMenuItems},
                {title: 'News',icon: 'mdi-bullhorn',Children: this.BottomNavNewsItems
                }
                ]
            }
        },
        ActiveSuiteMenuItems(){
            return this.ComputedDefaultMenuItems.concat(this.ComputedSecondaryMenuItems).map(item => {
                return item.Children
            }).flat()
        }
    },
    watch: {
        ActiveSearch:{
         handler: function(newvalue, oldvalue) {
          if(oldvalue !== newvalue){
            if(!newvalue){
                this.sitesearch = newvalue
            }
          }
          },
          deep: true
      },
        ActiveSuiteMenuItems:{
         handler: function(newvalue, oldvalue) {
          if(oldvalue !== newvalue){
            this.$store.commit('SetActiveSuiteMenuItems',this.ActiveSuiteMenuItems)
            //this.CheckforDefaultView()
          }
          },
          deep: true
      },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      ActiveWarehouse(value){
          if(value){
          this.SelectedWarehouse = value
          }
      },
      MiniView(value){
            if(value){
                this.TurnoffSiteDialog()
            }
            else{
                this.TurnonSiteDialog()
            }
        },
        ActiveSuiteApp: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.CheckforDefaultWarehouse()
                }
            },deep: true
        },
    RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                    this.CheckforDefaultView()
                    if(this.MiniView){
                    this.TurnoffSiteDialog()
                    }	
                    else{
                        this.TurnonSiteDialog()
                    }
                    if(newvalue.name === 'DirectoryBuilder'){
                        this.SiteSecondaryDialog = false
                    }
                    else{
                        if(!this.MiniView){                            
                        this.SiteSecondaryDialog = true
                        }
                    }
                }
            },
            deep: true
    }
    },
    created(){
        //console.log('created')
        this.GetTemplateEntities()
        this.CheckforDefaultWarehouse()
        this.CheckforDefaultView()
        if(this.ActiveWarehouse){
            this.SelectedWarehouse = this.ActiveWarehouse
        }
        if(this.MiniView){
          this.TurnoffSiteDialog()
        }	
        else{
            this.TurnonSiteDialog()
        }	
        if(this.ActiveSuiteApp){
            this.SelectedApp = this.ActiveSuiteApp
        }
        this.GetAllUserDashboards()
    },
    methods:{
        enttemplatefieldFilter (item, queryText, itemText) {
            const Primary = item.DisplayName.toLowerCase()
            const searchText = queryText.toLowerCase()
            return Primary.indexOf(searchText) > -1
        },
        CheckUpdatesTemplate(table){
            if(table.UpdatesTemplate){
                this.TableTemplateUpdate = table
                this.ratemplatestep++
            }
            else{
                this.TableTemplateUpdate = ''
            }
        },
        GetTemplateEntities(){
            //console.log(this.EntitiesArray)
            if(this.SystemisRA){
                //okay so if RAUserObj, if client suite, refer App./vue 'EntitiesArray' setcolleciton on clientappsignin
            //therefor this only applies if RA, in case the SuiteappNav is called prior to viewing "systems table" (as tablemain caters then for calling entitiesarray if not called)
        let ent = this.SystemEntities.find(obj => obj.id === 'Entities')
        let storequery = ent.id.split('_').join('')+'Query'
        let storearrayname = ent.id.split('_').join('')+'Array'
        if(this.$store.state[storequery]){
          if(this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
              let payload = {                
              query: this.$store.state[storequery],
              arraymutation: 'set'+storearrayname,
              getter: 'get'+storearrayname,
              arrayname: storearrayname
              }
              payload.SubCollections = [
              {
                Subcolpath: 'AllFields',
                SubcolName: 'AllFields'
              },
            ]
              this.$store.dispatch('GetArrayandMultipleSubcols',payload)
          }
        }
            }            
      },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(this.field,FieldObject,this.Record)
    },
        CheckReservedProps(prop){
        let officalprops = this['Reserved'+prop+'Props'].map(prop => {
          return prop.toLowerCase().split(' ').join('')
        })
        return [ 
            v => !!v || "This field is required",
            v => ( v && !officalprops.includes(v.toLowerCase().split(' ').join('')) ) || "This "+prop+" is already in use",
        ]
      },
        CheckExistingTables(prop){
        let tablepropslist = this.SystemEntities.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let photolibpropslist = this.SystemPhotoLibraries.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let doclibpropslist = this.SystemDocumentLibraries.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let officalprops = this['Reserved'+prop+'Props'].map(prop => {
          return prop.toLowerCase().split(' ').join('')
        })
        let finalpropslist = tablepropslist.concat(officalprops,photolibpropslist,doclibpropslist)
        return [ 
            v => !!v || "This field is required",
            v => ( v && !finalpropslist.includes(v.toLowerCase().split(' ').join('')) ) || "This "+prop+" is already in use",
        ]
      },
        Advanceratemplatestep(SelectedEntities,omittedentities){            
            if(this.ratemplatestep === 1){
              this.InstallingOmittedEntities = omittedentities  
            }
            if(this.ratemplatestep === 1 && !omittedentities){
                this.ratemplatestep++
            }
            else if(this.ratemplatestep === 1 && omittedentities){
                this.ratemplatestep = 4
            }
            else if(this.ratemplatestep === 4 && omittedentities){
                this.EntitiestoRATemplates(this.OmittedDefaultSystemEntities,this.InstallingOmittedEntities)                
            }
            else {
                this.EntitiestoRATemplates(SelectedEntities)
            }
        },
        CheckforDefaultView(){
            //console.log('this.SelectedMain',!this.SelectedMain,this.SelectedMain)
            if(this.ActiveSuiteApp.AppDefaultView && !this.SelectedMain && this.$route.name === 'SuiteApp'){
                let defview = this.ActiveSuiteMenuItems.find(obj => obj.title === this.ActiveSuiteApp.AppDefaultView)
                //console.log('defview',defview)
                if(defview){
                    //this.SelectedMain = defview
                    this.ActivateSelectedMain(defview,false,true)
                    
                }                
            }
        },
        ToggleIsolatedNavbarItem(){
            this.IsolatedPrimaryNavbarItem = ''
            this.IsolatedSecondaryNavbarItem = ''
            if(this.MiniView){
                this.primarymini = true
                this.secondarymini = true
            }
        },
        MiniViewIsolate(top,navbar){
            if(navbar === 'primary' && this.primarymini){
                this.IsolatedPrimaryNavbarItem = top
                //console.log(top)
            }
            else if(navbar === 'secondary' && this.primarymini){
                this.IsolatedSecondaryNavbarItem = top
                //console.log(top)
            }
        },
        Regressratemplatestep(){
            this.ratemplatestep--
            this.TableTemplateUpdate = ''
            this.InstallingOmittedEntities = false
        },
        CancelEntitiestoRAdialog(){
            this.EntitiestoRAdialog = false
            this.SelectedEntities = []
            this.AllSystemEntities = false
            this.TableTemplateUpdate = ''
            this.ratemplatestep = 1
            this.InstallingOmittedEntities = false
        },
        ActivatePackagetoRADialog(){
            this.EntitiestoRAdialog = true
        },
        EntitiestoRATemplates(array,omittedentities){
            //README - This method handles essentially pushing all entities of a SuiteApp, packaging the App as a template.
            //It handles two approaches though
            //1. It first ensures the required systementities are installed, and if not it does that first after which the dialog should again be opened to push the template App
            //2. If however all is fine it pushes a template
            //THEREFORE!!! Do not assume that anything needs to push to RA here. ONLY if omitted. 
            
            //THAT BEING SAID...confusion comes in now as to why we push anything to "entities"
            //Understand, unique configuration may apply to entities, they are configured for the App.
            //IT IS THE APP that gets pushed to marketplace, and the entities are linked to the app, so the app gets installed with the configured entities
            //which requires the entities need to be pushed to ra "entities"
            //So again - the only case where SystemConfig entities are touched is when it's about omitted entities nothing else

            //okay sure, that all makes sense however I still don't see the App being installed. you get?
            //where in this do we actually push any "app" to any "marketplace"? We are only installing entities to the entity template, but we need the App!!!
            //Technicall this would actually require installing "App" in a marketplace, linking the entities to that app, and ossibly even installing of plugins, 
            //so it's not just entities but also plugins that install with a marketplace App
            //And nevermind handling the installing of such an App
            //Therefore are this stage honestly this is NOT really functional at this stage. Except to install missing system entities on olrder Suites

            //just about it...but...consider plugin values
            //Creating_Plugin, Creating_Pluginid, PluginEntityid, PluginInstanceIndex, 
            let idarray = array.map(item => {
                return item.id
            })
            //right so we reassigned certain values...and addtionally well, yeah you get the deal. need to reinstate to canges that we allowed
            let entities = []
            if(omittedentities){
                entities = array
            }
            else{
                entities = this.SystemEntities.filter(ent => {
                return idarray.includes(ent.id)
            })
            }
            //console.log('entities',entities)
            let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                  entities.map(tableobj => {
                    //define rauser, for ra admin purposes on lookup references
                    let rauser = ''
                    if(this.SystemisRA){
                        rauser = this.userLoggedIn
                    }
                    else{
                        rauser = this.RAUserObj
                    }
                    let newdocid = ''
                    let matchent = array.find(obj => obj.id === tableobj.id)
                    let finaltable = Object.assign({},tableobj)
                    delete finaltable.id
                    subcols.map(prop => {
                        delete finaltable[prop]
                    })
                    if(matchent){
                       this.EntityTemplateFields.map(fieldname => {
                            finaltable[fieldname] = matchent[fieldname]
                        }) 
                    }                    
                    //modified by and modified on rauser
                    finaltable.Modified_By= {Name: rauser.Name,Surname: rauser.Surname,Full_Name: rauser.Full_Name,id: rauser.id}
                    finaltable.Modified_Byid = rauser.id
                    finaltable.Modified_On = new Date()
                    if(matchent && matchent.Templateid){
                        newdocid = matchent.Templateid
                    }
                    else{
                    //will need a created on because not updating a template
                    finaltable.Created_By= {Name: rauser.Name,Surname: rauser.Surname,Full_Name: rauser.Full_Name,id: rauser.id}
                    finaltable.Created_Byid = rauser.id
                    finaltable.Created_On = new Date()
                    }
                    if(finaltable.Security_Structure.Name === 'Custom Roles'){
                        finaltable.CustomCreateRoles = []
                        finaltable.CustomDeleteRoles = []
                        finaltable.CustomListRoles = []
                        finaltable.CustomGetRoles = []
                        finaltable.CustomUpdateRoles = []
                    }
                    else if(finaltable.Security_Structure.Name === 'Business Unit'){
                        this.$store.state.BURoles.map(role => {
                        finaltable[role.Name] = []
                        })
                    }
                    delete finaltable.CanCreate
                    delete finaltable.CanDelete
                    delete finaltable.CanEdit
                    delete finaltable.CanView
                    delete finaltable.Primary_Appid
                    delete finaltable.QuickDashElements
                    finaltable.Template_System = {
                        Name: this.System.Name,
                        id: process.env.VUE_APP_RA_SYSTEM_ID
                    }
                    finaltable.Template_Systemid = process.env.VUE_APP_RA_SYSTEM_ID
                    //console.log(finaltable)
                    let status = this.EntityMasterFields.find(obj => obj.id === 'Status')
                    let Status = status.DefaultOption
                    let Status_Reason = status.DefaultLevel2Option
                    finaltable.Status = Status
                    finaltable.Status_Reason = Status_Reason
                    let ref = ''
                    let path = ''
                     let rapath = ''
                    if(omittedentities){
                    //basically if omittedentities the path is SystemConfig and env id...because from templates we now installing all entities missed whether on client suite or even RA
                    //however if not RA we also need to install under RA itself
                        path = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
                        newdocid = tableobj.DisplayName.split(' ').join('_')
                        rapath = this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(newdocid)
                    }
                    else if(this.SystemisRA){
                        path = db.collection('entities')
                    }
                    else{
                        path = this.RADB.collection('entities')
                    }
                    ref = path.doc()
                    if(!newdocid){
                        newdocid = ref.id
                    }                    
                    let entpath = path.doc(newdocid)
                    console.log(entpath)
                    //right okay so there is a LIIITLE bit more to this. It needs to interrogate a couple of things
                    //1. It must assume if it's either 'Users" or whatever, that it should NOT add it, in fact 
                            
                    this.SetNewEntities(entpath,tableobj,finaltable,subcols,rapath)
                    // entpath.set(finaltable).then(radocadd => {
                    //     subcols.map(subcol => {
                    //         tableobj[subcol].map(subcolentry => {
                    //             entpath.collection(subcol).doc(subcolentry.id).set(subcolentry).then(subcolentrydoc => {
                    //                 if(omittedentities && !this.SystemisRA){
                    //                    this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc() 
                    //                 }
                    //             })
                    //         })
                    //     })
                    // })
                  })
                 this.CancelEntitiestoRAdialog() 
                            
        },
        SetNewEntities(entpath,tableobj,finaltable,subcols,rapath){
            entpath.set(finaltable).then(radocadd => {
                        subcols.map(subcol => {
                            tableobj[subcol].map(subcolentry => {
                                entpath.collection(subcol).doc(subcolentry.id).set(subcolentry).then(subcolentrydoc => {
                                    if(rapath){
                                       rapath.set(finaltable).then(radocadd => {
                                        subcols.map(subcol => {
                                            tableobj[subcol].map(subcolentry => {
                                                rapath.collection(subcol).doc(subcolentry.id).set(subcolentry).then(subcolentrydoc => {

                                                })
                                            })
                                        })
                                    })
                                    }
                                })
                            })
                        })
                    })
        },
        EntitiestoRA(array){
            let entities = this.SystemEntities.filter(ent => {
                return array.includes(ent.id)
            })
            console.log(entities)
            let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                  entities.map(tableobj => {
                    let finaltable = Object.assign({},tableobj)
                    delete finaltable.id
                    subcols.map(prop => {
                        delete finaltable[prop]
                    })
                    //console.log(finaltable)
                    let entpath = this.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('entities').doc(tableobj.id)
                    //console.log(entpath)
                    entpath.set(finaltable).then(radocadd => {
                        subcols.map(subcol => {
                            tableobj[subcol].map(subcolentry => {
                                entpath.collection(subcol).doc(subcolentry.id).set(subcolentry).then(subcolentrydoc => {

                                })
                            })
                        })
                    })
                  })
                  
        },
        CheckforDefaultWarehouse(){
            if(this.ActiveSuiteApp.AppNavbarSecondary === 'Warehouse Selector'){
            let app =''
            let plugin = this.AppandSharedPlugins.find(obj => obj.id === 'Warehouse_Inbound')
             if(plugin && !plugin.Primary_Appid || plugin && plugin.Primary_Appid === this.ActiveSuiteApp.id){
                app = this.ActiveSuiteApp
            }
            else if(plugin){
                app = this.AppsDataBase.find(obj => obj.id === plugin.Primary_Appid)
            }
            else if(!plugin){
                app = this.ActiveSuiteApp
            }
            if(app && app.Default_Warehouseid){
                this.SelectedWarehouse = this.$store.state.WarehousesArray.find(obj => obj.id === app.Default_Warehouseid)
                if(this.SelectedWarehouse){
                    this.SetActiveWarehouse(this.SelectedWarehouse)
                }
            }
           
            console.log(plugin,app)
        }
        },
        UpdateAppProp(prop){
        this.AppsPath.doc(this.ActiveSuiteApp.id).update({
          [prop]: this.ActiveSuiteApp[prop]
        })
      },
        SetActiveWarehouse(wh){
            this.$store.commit('setActiveWarehouse',wh)
        },
        GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('Databases').doc(this.$store.state.ActiveSuiteid).collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            this.UserDashboards.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
        })
      })
    },
        ActivateSearch(){    
      this.$emit('DeactivateSearch')
      //console.log(this.sitesearch) 
      this.SearchView = true
      this.$emit('ActivateSearch',this.sitesearch)
    },
        IntranetViewToggle(){
      //confirm('This will take you to the Intranet version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',true,true)
      },
        ToggleAppDarkMode(){
      this.$emit('ToggleAppDarkMode',!this.$vuetify.theme.dark)
    },
        CheckSuiteAppRoute(){
            if(this.SelectedApp.id !== this.ActiveSuiteApp.id){
                this.$router.push('/App/'+this.SelectedApp.id)
                this.SetActiveWarehouse('')
            }
        },
        AssignSocialItemInteractMethod(item,SecondProp){
        //console.log('AssignSocialItemInteractMethod',item,SecondProp)
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','')
        if(SecondProp){
            this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        else if(item){
            this.$store.commit('SetSocialItemInteractMethod',item)
        }
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        SelectBottomNavMenuItem(main){
            if(main.BypassMethod){
                this[main.BypassMethod]()
            }
            else{
                this.ActivateSelectedMain(main)
            }
        },
        ActivateSelectedMain(main,frompredial,defview){
            //console.log(this.$route)
            //1. start with method, because wnat to know if is route. because it not, current route may perhaps not be active suite "/App/CRM" and thus menu navigation fails
            if(this.$route.name === 'SuiteApp' || main.Route){

            }
            else if(!defview){
                this.$router.push('/App/'+this.ActiveSuiteApp.id)
            }
            //console.log(main)
            this.ScrolltoTop()
            if(main.PreDialog && !frompredial){
                this.SelectedMain = main
            }
            else if(main.SecondProp && main.SecondProp.PreDialog && !frompredial){
                this.SelectedMain = main
            }
            else if(main.Method){               
                this.SelectedMainDialog = false
                this.SelectedMain = ''
                let newpath = ''
                if(main.Route){
                    newpath = '/'+main.Route
                }
                if(main.Prop === 'setActiveAppComponent'){
                    this.SelectedMain = main
                }
                //console.log(newpath,this.$route.path)
                if(newpath && this.$route.path !== newpath){
                    
                    this.$router.push('/'+main.Route)
                    setTimeout(() => {
                        //console.log('s not path')
                    if(main.Prop === 'setActiveAppComponent'){
                    this.SelectedMain = main
                }
                        //console.log('oign to fire')
                     this.AssignSocialItemInteractMethod(main.Prop,main.SecondProp)     
                    }, 300);
                }
                else{
                    
                    setTimeout(() => {
                        //console.log('either is path or has not path here is newpath -',newpath)
                    this.AssignSocialItemInteractMethod(main.Prop,main.SecondProp)  
                     }, 300);
                }
            }
            else if(main.Route && this.$route.path !== '/'+main.Route){
                this.SelectedMain = ''
                this.$router.push('/'+main.Route)
            }
            else{
                this.SelectedMain = main
            }
            //console.log('main',main,'main this.SelectedMain',this.SelectedMain)
        },
        ToggleSiteSecondaryDialog(){
           this.SiteSecondaryDialog = !this.SiteSecondaryDialog
        },
        TurnoffSiteDialog(){
            this.$store.commit('setSiteDialog',false)
            this.SiteSecondaryDialog = false
            if(this.MiniView){                
                this.primarymini = true
            }
            this.IsolatedPrimaryNavbarItem = ''
        },
        TurnonSiteDialog(){
            this.$store.commit('setSiteDialog',true)
            if(!this.MiniView){                
                this.primarymini = false
                this.SiteSecondaryDialog = true
            }
        },
        ToggleSiteDialog(){
            if(this.SiteDialog && this.MiniView){
                this.primarymini = true
                this.IsolatedPrimaryNavbarItem = ''
            }
            this.$store.commit('setSiteDialog',!this.SiteDialog)
        },
    }
}
</script>

<style>

</style>



